import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import ErrorBoundary from 'helpers/ErrorBoundary';
import { withTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

function TrainingMaterial() {
    
    // eslint-disable-next-line no-undef
    const remoteImport = async url => (await SystemJS.import(url)).default;
  
    return (
        <ErrorBoundary>
          <Router>
            <Route
              path="/trainingmaterial/dashboard"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/training')}
                  mountParcel={mountRootParcel}
                  wrapWith="section"
                  className="app-container"
                  Auth={Auth}
                />
              )}
            />
          </Router>
        </ErrorBoundary>
    );
  }

export default withTranslation()(TrainingMaterial);
