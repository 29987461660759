import Axios from 'axios';
import { Auth } from 'aws-amplify';
import { split, includes, get } from 'lodash';
import { getAlreadyReadBanners, setAlreadyReadBanners } from '../util/helpers';

const baseURL = process.env.REACT_APP_DASHBOARD_BFF;
const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getIdToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return token;
};

const getRequestHeaders = async () => ({
  Authorization: `Bearer ${await getIdToken()}`,
  'Content-Type': 'application/json'
});

const getSyscoUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  var oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

export const getUser = async () => {
  const {
    user: { username: userId }
  } = Auth;

  let user = {
    details: {},
    vendor: {
      vendorId: null,
      vendorName: null
    },
    userRole: null
  };

  const id = getSyscoUserId(userId);

  try {
    const res = await Axios.get(`${baseURL}/user/${id}`, { headers: await getRequestHeaders() });

    let venderRes = false;
    if (res.data && res.data.vendor && res.data.vendor.vendorId > 0) {
      try {
        venderRes = await Axios.get(`${baseURL}/user/suvc/${res.data.vendor.vendorId}`, {
          headers: await getRequestHeaders()
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (res.data.roles && res.data.roles.filter(r => r>=500 && r<2000).length > 0) {
      user = { details: res.data, userRole: 'associate' };
    } else if (res.data.roles && res.data.roles.filter(r => r >=2000 && r<3000).length > 0 && !(get(res.data, 'vendor.vendorId', 0) > 0)) {
      user = { details: res.data, userRole: 'broker' };
    } else if (res.data.roles && res.data.roles.filter(r => r >0 && r<500).length > 0 && get(res.data, 'vendor.vendorId', 0) > 0) {
      user = { details: res.data, vendor: res.data.vendor, userRole: 'vendor' };
    } else {
      user = { details: res.data, userRole: 'unknown' };
    }

    localStorage.setItem('vendor', JSON.stringify(venderRes.data));

    user.details.supplierName = venderRes && venderRes.data && venderRes.data.name ? venderRes.data.name : '';
  } catch (error) {
    console.log(error);
  }
  user = {...user, banners: []};
  const audienceTypes = {
    associate: 'colleague',
    broker: 'broker',
    vendor: 'supplier',
    unkown: 'unkown'
  }
  try {
    let audience = user && user.userRole && audienceTypes[user.userRole] || null;
    if (audience) {
      const banners = await getBanners(audience);
      user = { ...user, banners };
    }
    
  } catch (error) {
    console.log(error);
  }

  return user;
};

export const readBanners = async (id) => {
  try {
    const response = await Axios.patch(`${baseURL}/user/audience/banners/${id}`, {},  { headers: await getRequestHeaders() });
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getBanners = async (audience) => {
  try {
    const response = await Axios.get(`${baseURL}/user/audience/banners?audience=${audience}`, { headers: await getRequestHeaders() });
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return [];
};
