import userRoles from 'enums';

export default function(cognitoGroup) {
  switch (cognitoGroup) {
    case userRoles.OPCO_MERCH:
      return 'landing.rolesMapper.opcomerch';
    case userRoles.VENDOR:
      return 'landing.rolesMapper.vendor';
    case userRoles.CORP_MERCH:
      return 'landing.rolesMapper.corpmerch';
    case userRoles.ADMIN:
      return 'landing.rolesMapper.admin';
    case userRoles.ASSOCIATE:
      return 'landing.rolesMapper.associate';
    case userRoles.UNKNOWN:
      return 'landing.rolesMapper.unknown';
    case userRoles.BROKER:
      return 'landing.rolesMapper.broker';
    default:
      return 'landing.rolesMapper.vendor';
  }
}
