import React from 'react';
// import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import userContext from 'context/User';

export class VendorSwitch extends React.Component {
  static contextType = userContext;

  state = {
    visible: false
  };

  toggleMenu = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  render() {
    // const { visible } = this.state;
    // const { selectedVendor, userDetails } = this.context;
    // const { t, selectVendor } = this.props;
    return (
      <React.Fragment>
        {/* <div role="button" tabIndex="0" className="organisation" onClick={this.toggleMenu} onKeyPress={this.toggleMenu}> */}
        {/*  <div className="current-vendor"> */}
        {/*    <div className="type">{t('landing.vendor')}</div> */}
        {/*    <div id="vendorname" className="name" title="ACME Seafood Company"> */}
        {/*      {`${selectedVendor.vendorId} - ${selectedVendor.vendorName}`} */}
        {/*    </div> */}
        {/*    <i className="fi flaticon-caret-down"></i> */}
        {/*  </div> */}
        {/*  <ul id="vendorlist" className={visible ? 'vendor-switcher show' : 'vendor-switcher'}> */}
        {/*    {Object.keys(userDetails).length > 0 && */}
        {/*      userDetails.vendorList.map(vendor => ( */}
        {/*        <li key={vendor.vendorId} onClick={selectVendor.bind(this, vendor)}> */}
        {/*          {`${vendor.vendorId} - ${vendor.vendorName}`} */}
        {/*        </li> */}
        {/*      ))} */}
        {/*  </ul> */}
        {/* </div> */}
        {/* {visible && ( */}
        {/*  <div */}
        {/*    role="button" */}
        {/*    tabIndex="-4" */}
        {/*    onKeyPress={() => {}} */}
        {/*    className="backgroundClickContainer" */}
        {/*    onClick={this.toggleMenu} */}
        {/*  /> */}
        {/* )} */}
      </React.Fragment>
    );
  }
}

// VendorSwitch.propTypes = {
//   // selectVendor: PropTypes.func.isRequired
// };
export default withTranslation()(VendorSwitch);
