import React from 'react';

const AuthContext = React.createContext({
  currentUser: {},
  updateCurrentUser: null,
  isLoaded: false,
  banners: [],
  alreadyReadBanners: []
});
export default AuthContext;
