import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import Amplify from 'aws-amplify';
import App from './MainApp';
import * as serviceWorker from './serviceWorker';
import ampConfig from './helpers/AmplifyConfig';
import './_i18n';

Amplify.configure(ampConfig);

ReactDOM.render(<App />, document.getElementById('root'));

console.info(`Landing-UI-released on:${process.env.REACT_APP_BUILD_TIME}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
