import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from 'context/Auth';
import AppLoader from './AppLoader';

class ProtectedRoute extends React.Component {
  static contextType = AuthContext;

  componentDidMount() {
    const { history } = this.props;
    const { updateCurrentUser } = this.context;
    this.unlisten = history.listen(() => {
      updateCurrentUser();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  isNoRoleError = () => {
    const { userRole } = this.context;
    return !userRole;
  };

  getRouteVariables = (type, isAuthenticated) => {
    switch (type) {
      case 'LoginRoute':
        return [!isAuthenticated, 'suite/dashboard'];
      case 'PrivateRoute':
        return this.getVariablesForPrivateRoutes(isAuthenticated);
      case 'ErrorRoute':
        return this.getVariablesForErrorRoutes(isAuthenticated);
      default:
        return [isAuthenticated, '/login'];
    }
  };

  getVariablesForPrivateRoutes = isAuthenticated => {
    // if (isAuthenticated) {
    //   if (this.isNoRoleError()) {
    //     return [!isAuthenticated, '/error', { isNoRoleError: true }];
    //   }
    // }
    return [isAuthenticated, '/login'];
  };

  getVariablesForErrorRoutes = isAuthenticated => {
    if (isAuthenticated) {
      // if (this.isNoRoleError()) {
      //   return [isAuthenticated, '/error', { isNoRoleError: true }];
      // }
      return [!isAuthenticated, '/suite/dashboard'];
    }
    return [isAuthenticated, '/login'];
  };

  render() {
    const { component: Component, type, setSelectedVendor, ...rest } = this.props;
    const { currentUser, isLoaded } = this.context;
    const isAuthenticated = !!(currentUser && currentUser.username);
    if (!isLoaded) return <AppLoader />;

    const [switcher, redirectPath, payload] = this.getRouteVariables(type, isAuthenticated);
    return (
      <Route
        {...rest}
        size
        render={props => {
          return switcher ? (
            <Component setSelectedVendor={setSelectedVendor} {...props} {...payload} />
          ) : (
            <Redirect
              to={{
                pathname: redirectPath,
                payload
              }}
            />
          );
        }}
      />
    );
  }
}

ProtectedRoute.propTypes = {
  type: PropTypes.string.isRequired
};

export default withRouter(ProtectedRoute);
