import React from 'react';
import PropTypes from 'prop-types';

export default function AppThumbnail({ divClassName, spanClassName, appName }) {
  return (
    <li className="tile-wrapper">
      <div className={divClassName}>
        <div className="icon">
          <span className={spanClassName}></span>
        </div>
        <div className="divider"></div>
        <div className="title">{appName}</div>
      </div>
      <div className="tile-info">{appName}</div>
    </li>
  );
}

AppThumbnail.propTypes = {
  divClassName: PropTypes.string.isRequired,
  spanClassName: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired
};
