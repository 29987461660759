import * as _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
const crypto = require('crypto');

const processToHash = input => {
  // Trim whitespace
  const trimmed = input.trim();

  // Convert to lowercase
  const lowercased = trimmed.toLowerCase();

  // Create MD5 hash
  return crypto
    .createHash('md5')
    .update(lowercased)
    .digest('hex');
};


const IntercomProvider = ({ user }) => {

  let suvc = _.get(user, 'vendor.vendorId', 0);
  let email = _.get(user, 'username', '').toLowerCase().replace(/okta_/i, '');

  const showIntercomMessenger = `${email}`.includes('@') && suvc > 0 && !(`${suvc}`.match(/^1888/i) && `${suvc}`.length > 6);

  useEffect(() => {

    if (!showIntercomMessenger) {
      return () => {};
    }

    const script = document.createElement('script');
    script.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`; 
    script.async = true;
    document.body.appendChild(script);

    console.log(`Intercom email: `, email, '| app_id:', process.env.REACT_APP_INTERCOM_APP_ID);

    script.onload = () => {
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          user_id: processToHash(email)
        });
        window.Intercom('show');
      }
    };

    return () => {
      document.body.removeChild(script);
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  return null; 
};

IntercomProvider.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default IntercomProvider;


