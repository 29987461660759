import React from 'react';
import { isMobile } from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';

const Notification = ({ banners, handleBannerClose }) => {
    if (banners && banners.length) {
        try {
            return <div className={`notification-section${ isMobile ? ' mobile': ''}`}>
                    <div className='notification-header'>You have Notifications...</div>
                    <div className='outer-notification-title-body-wrapper'>
                    {banners.map(({ id, title, message, backgroundColor, styles }, index) => {
                        return <div id={id} className='notification-title-body-wrapper' key={`notification-row-${id}`}>
                            <span className="inner-notification-clear-icon" title='CLOSE' onClick={() => handleBannerClose(id)}>X</span>
                            <div className='inner-notification-section' style={{backgroundColor}}>
                                <div className='notification-title'>{title}</div>
                                <div className='notification-body' style={styles}>{ReactHtmlParser(message)}</div>
                            </div>
                        </div>
                    })}
                    </div>
                </div>
        } catch (e) {
            console.log(`error while trying to render banners`);
        }
    }
    return null;
}

export default Notification;
