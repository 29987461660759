const userRoles = {
  VENDOR: 'vendor',
  CORP_MERCH: 'corpmerch',
  OPCO_MERCH: 'opcomerch',
  ADMIN: 'admin',
  ASSOCIATE: 'associate',
  BROKER: 'broker',
  UNKNOWN: 'unknown'
};

export default userRoles;
