export const getAlreadyReadBanners = () => {
    // Read data from local storage
    const banners = getLocalStorage('banners');
    if (banners) {
        // If data exists in local storage, parse it and set it to state
        return JSON.parse(banners);
    }
    return [];
}

export const setAlreadyReadBanners = (key, value) => {
    window.localStorage.setItem(key, value);
}

export const getLocalStorage = (key) => {
    // Read data from local storage
    return window.localStorage.getItem(key);
}

export const getUserType = ({ roles, vendor } = { roles: [], vendor: {} }) => {
    if (roles && roles.filter(r => r >= 500 && r < 2000).length > 0) {
        return 'colleague';
    } else if (roles && roles.filter(r => r >= 2000 && r < 3000).length > 0 && vendor.vendorId > 0) {
        return 'broker';
    } else if (roles && roles.filter(r => r > 0 && r < 500).length > 0 && vendor.vendorId > 0) {
        return 'supplier';
    } else {
        return 'unknown';
    }
}
