import React from 'react';
import PropTypes from 'prop-types';
import AppBar from 'components/AppBar/AppBar';
import logo from '../styles/images/sysco-logo.png';
import errorImg from '../styles/images/error.png';
import noRoleImg from '../styles/images/error-norole.png';

// adding here in-order to have these resources in the build
import dashIcon from '../styles/images/icons/dashboard-icon.svg';
import bCrumIcon from '../styles/images/icons/bread-crum.svg';
import searchIcon from '../styles/images/icons/search.svg';
import backArrow from '../styles/images/icons/back-arrow.svg';
import graphBackground from '../styles/images/icons/graph-background.svg';
import timer from '../styles/images/icons/timer.svg';
import sendBtn from '../styles/images/icons/send.svg';
import sendFeedbackBtn from '../styles/images/icons/send-feedback-group.svg';
import searchNewIcon from '../styles/images/icons/search-icon.svg';
import sendIcon from '../styles/images/icons/send-icon.svg';

import contactsMegan from '../styles/images/contacts/megan.png';
import contactsTammy from '../styles/images/contacts/tammy.png';

import openField from '../styles/images/open-field.png';
import iconSustain from '../styles/images/sustainability.png';
import iconSustainSmall from '../styles/images/sus-icon.png';
import deiIcon from '../styles/images/dei.svg';

import brixSan from '../styles/images/fonts/BrixSansRegular.otf';
import BrixSansBoldeot from '../styles/images/fonts/BrixSans-Bold.eot';
import BrixSansBoldsvg from '../styles/images/fonts/BrixSans-Bold.svg';
import BrixSansBoldttf from '../styles/images/fonts/BrixSans-Bold.ttf';
import BrixSansBoldwoff from '../styles/images/fonts/BrixSans-Bold.woff';
import BrixSansExtraLighteot from '../styles/images/fonts/BrixSans-ExtraLight.eot';
import BrixSansExtraLightsvg from '../styles/images/fonts/BrixSans-ExtraLight.svg';
import BrixSansExtraLightttf from '../styles/images/fonts/BrixSans-ExtraLight.ttf';
import BrixSansExtraLightwoff from '../styles/images/fonts/BrixSans-ExtraLight.woff';
import BrixSansExtraLightwoff2 from '../styles/images/fonts/BrixSans-ExtraLight.woff2';
import BrixSansLightItaliceot from '../styles/images/fonts/BrixSans-Light-Italic.eot';
import BrixSansLightItalicttf from '../styles/images/fonts/BrixSans-Light-Italic.ttf';
import BrixSansLightItalicwoff from '../styles/images/fonts/BrixSans-Light-Italic.woff';
import BrixSansLightItalicwoff2 from '../styles/images/fonts/BrixSans-Light-Italic.woff2';
import BrixSansLighteot from '../styles/images/fonts/BrixSans-Light.eot';
import BrixSansLightttf from '../styles/images/fonts/BrixSans-Light.ttf';
import BrixSansLightwoff from '../styles/images/fonts/BrixSans-Light.woff';
import BrixSansLightwoff2 from '../styles/images/fonts/BrixSans-Light.woff2';
import BrixSansMediumeot from '../styles/images/fonts/BrixSans-Medium.eot';
import BrixSansMediumttf from '../styles/images/fonts/BrixSans-Medium.ttf';
import BrixSansMediumwoff from '../styles/images/fonts/BrixSans-Medium.woff';
import BrixSansMediumwoff2 from '../styles/images/fonts/BrixSans-Medium.woff2';
import BrixSansRegulareot from '../styles/images/fonts/BrixSans-Regular.eot';
import BrixSansRegularttf from '../styles/images/fonts/BrixSans-Regular.ttf';
import BrixSansRegularwoff from '../styles/images/fonts/BrixSans-Regular.woff';
import BrixSansRegularwoff2 from '../styles/images/fonts/BrixSans-Regular.woff2';
import DuplicateSlabLightItaliceot from '../styles/images/fonts/DuplicateSlab-Light-Italic.eot';
import DuplicateSlabLightItalicsvg from '../styles/images/fonts/DuplicateSlab-Light-Italic.svg';
import DuplicateSlabLightItalicttf from '../styles/images/fonts/DuplicateSlab-Light-Italic.ttf';
import DuplicateSlabLightItalicwoff from '../styles/images/fonts/DuplicateSlab-Light-Italic.woff';
import DuplicateSlabLighteot from '../styles/images/fonts/DuplicateSlab-Light.eot';
import DuplicateSlabLightsvg from '../styles/images/fonts/DuplicateSlab-Light.svg';
import DuplicateSlabLightttf from '../styles/images/fonts/DuplicateSlab-Light.ttf';
import DuplicateSlabLightwoff from '../styles/images/fonts/DuplicateSlab-Light.woff';
import DuplicateSlabRegularItaliceot from '../styles/images/fonts/DuplicateSlab-Regular-Italic.eot';
import DuplicateSlabRegularItalicsvg from '../styles/images/fonts/DuplicateSlab-Regular-Italic.svg';
import DuplicateSlabRegularItalicttf from '../styles/images/fonts/DuplicateSlab-Regular-Italic.ttf';
import DuplicateSlabRegularItalicwoff from '../styles/images/fonts/DuplicateSlab-Regular-Italic.woff';
import DuplicateSlabRegulareot from '../styles/images/fonts/DuplicateSlab-Regular.eot';
import DuplicateSlabRegularsvg from '../styles/images/fonts/DuplicateSlab-Regular.svg';
import DuplicateSlabRegularttf from '../styles/images/fonts/DuplicateSlab-Regular.ttf';
import DuplicateSlabRegularwoff from '../styles/images/fonts/DuplicateSlab-Regular.woff';
import DuplicateSlabThineot from '../styles/images/fonts/DuplicateSlab-Thin.eot';
import DuplicateSlabThinotf from '../styles/images/fonts/DuplicateSlab-Thin.otf';
import DuplicateSlabThinsvg from '../styles/images/fonts/DuplicateSlab-Thin.svg';
import DuplicateSlabThinttf from '../styles/images/fonts/DuplicateSlab-Thin.ttf';
import DuplicateSlabThinwoff from '../styles/images/fonts/DuplicateSlab-Thin.woff';

function LoginMessage({ isNoRoleError, location }) {
  const isNoRoleErrorSwitcher = isNoRoleError || location.isNoRoleError;
  if (
    searchNewIcon ||
    sendIcon ||
    dashIcon ||
    bCrumIcon ||
    brixSan ||
    searchIcon ||
    backArrow ||
    timer ||
    sendBtn ||
    sendFeedbackBtn ||
    BrixSansBoldeot ||
    BrixSansBoldsvg ||
    BrixSansBoldttf ||
    BrixSansBoldwoff ||
    BrixSansExtraLighteot ||
    BrixSansExtraLightsvg ||
    BrixSansExtraLightttf ||
    BrixSansExtraLightwoff ||
    BrixSansExtraLightwoff2 ||
    BrixSansLightItaliceot ||
    BrixSansLightItalicttf ||
    BrixSansLightItalicwoff ||
    BrixSansLightItalicwoff2 ||
    BrixSansLighteot ||
    BrixSansLightttf ||
    BrixSansLightwoff ||
    BrixSansLightwoff2 ||
    BrixSansMediumeot ||
    BrixSansMediumttf ||
    BrixSansMediumwoff ||
    BrixSansMediumwoff2 ||
    BrixSansRegulareot ||
    BrixSansRegularttf ||
    BrixSansRegularwoff ||
    BrixSansRegularwoff2 ||
    DuplicateSlabLightItaliceot ||
    DuplicateSlabLightItalicsvg ||
    DuplicateSlabLightItalicttf ||
    DuplicateSlabLightItalicwoff ||
    DuplicateSlabLighteot ||
    DuplicateSlabLightsvg ||
    DuplicateSlabLightttf ||
    DuplicateSlabLightwoff ||
    DuplicateSlabRegularItaliceot ||
    DuplicateSlabRegularItalicsvg ||
    DuplicateSlabRegularItalicttf ||
    DuplicateSlabRegularItalicwoff ||
    DuplicateSlabRegulareot ||
    DuplicateSlabRegularsvg ||
    DuplicateSlabRegularttf ||
    DuplicateSlabRegularwoff ||
    DuplicateSlabThineot ||
    DuplicateSlabThinotf ||
    DuplicateSlabThinsvg ||
    DuplicateSlabThinttf ||
    DuplicateSlabThinwoff ||
    graphBackground ||
    contactsMegan ||
    contactsTammy ||
    openField ||
    iconSustain ||
    iconSustainSmall ||
    deiIcon
  ) {
    // loading images
  }
  const getErrorDetails = () =>
    isNoRoleErrorSwitcher ? (
      <React.Fragment>
        <img src={errorImg} alt="Sorry your not ready yet." className="errorimg norole" />
        <div className="subtitle-b">
          Looks like there are a few more things we need to do from our end to get you started - like assigning you a
          proper user role. Please contact support services for more details.
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <img src={noRoleImg} alt="Sorry your not ready yet." className="errorimg" />
        <div className="subtitle-b">
          Hi there! We are experiencing some technical issues at the moment and would be back momentarily. Please try
          again later and if the issue persists, contact support services.
        </div>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <AppBar />
      <div className="wrapper login-wrapper">
        <div className="login-panel login-message">
          <img src={logo} alt="Sysco Supplier Suite" className="logo" />
          {getErrorDetails()}
        </div>
      </div>
    </React.Fragment>
  );
}

LoginMessage.propTypes = {
  isNoRoleError: PropTypes.bool.isRequired
};
export default LoginMessage;
