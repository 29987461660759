import React from 'react';

const UserContext = React.createContext({
  userDetails: {},
  selectedVendor: {
    vendorId: null,
    vendorName: null
  },
  userRole: null,
  isSettingsLoading: false,
  supplierName: '',
  banners: [],
  alreadyReadBanners: []
});
export default UserContext;
