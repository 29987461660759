import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Auth, Hub, Logger } from 'aws-amplify';
import LoginPage from 'pages/LoginPage';
import TrainingMaterial from 'pages/TrainingMaterial';

import ProtectedRoute from 'components/ProtectedRoute';
import VendorLogin from 'components/VendorLogin';
import AuthContext from 'context/Auth';
import UserContext from 'context/User';
import App from 'App';
import LoginMessage from 'components/LoginMessage';
import initiateNotificationTrigger from './components/SessionExpireNotification';
import { getUser } from './helpers/UserUtil';
// Keep primary scss file at the bottom of the import list to avoid library overrides of styles
import 'styles/App.scss';
import 'react-phone-number-input/style.css'

const logger = new Logger('Auth-Logger');

function MainApp() {
  const [currentUser, setCurrentUser] = useState({});
  const [userRole, setUserRole] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSettingsLoading, setSettingsLoading] = useState(false);
  const [banners, setBanners] = useState([])

  const [userDetails, setUserDetails] = useState({});
  const [selectedVendor, setSelectedVendor] = useState({
    vendorId: null,
    vendorName: null
  });

  const setUserData = useCallback(async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      setSettingsLoading(true);
      const userData = await getUser(data);
      const { details, vendor, userRole: role, banners } = userData;
      setUserRole(role);
      setUserDetails(details);
      setSelectedVendor(vendor);
      setSettingsLoading(false);
      setBanners(banners);
    } catch (error) {
      setSettingsLoading(false);
      console.log('User Auth Details fetching:', error);
    }
  }, [setUserDetails, setSelectedVendor, setBanners]);

  const updateCurrentUser = useCallback(
    async user => {
      if (user) {
        setCurrentUser(user);
        setUserData();
        return;
      }
      try {
        const newUser = await Auth.currentAuthenticatedUser();
        setCurrentUser(newUser);
        setIsLoaded(true);
      } catch (err) {
        setCurrentUser({});
        setIsLoaded(true);
      }
    },
    [setCurrentUser, setIsLoaded, setUserData]
  );

  useEffect(() => {
    updateCurrentUser();

    const listener = data => {
      switch (data.payload.event) {
        case 'signIn':
          updateCurrentUser(data.payload.data);
          initiateNotificationTrigger();
          break;
        case 'signOut':
          updateCurrentUser();
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        default:
          logger.info(data.payload.event);
      }
    };

    if (isLoaded) Hub.listen('auth', listener);
  }, [isLoaded, updateCurrentUser]);

  useEffect(() => {
    setUserData();
  }, [setUserData]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userRole,
        updateCurrentUser,
        isLoaded,
        banners
      }}
    >
      <UserContext.Provider
        value={{
          userDetails,
          userRole,
          selectedVendor,
          isSettingsLoading,
          banners
        }}
      >
        <Router>
          <Switch>
            <ProtectedRoute path="/login" component={LoginPage} type="LoginRoute" />
            <ProtectedRoute path="/vendorlogin" component={VendorLogin} type="LoginRoute" />
            <ProtectedRoute path="/suite" component={App} type="PrivateRoute" setSelectedVendor={setSelectedVendor} />
            <ProtectedRoute path="/error" component={LoginMessage} type="ErrorRoute" />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/trainingmaterial" component={TrainingMaterial} />
            <Route path="*" component={LoginPage} />
          </Switch>
        </Router>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}
export default MainApp;
