import React from 'react';
import logo from '../styles/images/sysco-logo.png';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

let reactAppRedirectSignin = window.location.origin + process.env.REACT_APP_REDIRECT_SIGNIN;

function handleClick() {
  // do something meaningful, Promises, if/else, whatever, and then
  // Auth.federatedSignIn({ provider: 'adprovider', customState: 'nadun' });
  window.location.href = `https://${process.env.REACT_APP_DOMAIN}/oauth2/authorize?redirect_uri=${reactAppRedirectSignin}&response_type=TOKEN&client_id=${process.env.REACT_APP_USER_CLIENT_ID}&identity_provider=adprovider&scopes=email%2Copenid`;
}

function handleClickVendorOrBroker() {
  console.log(
    `https://${process.env.REACT_APP_OKTA_COGNITO_SUBDOMAIN}/oauth2/authorize?response_type=TOKEN&identity_provider=Okta&client_id=${process.env.REACT_APP_OKTA_COGNITO_CLIENT}&redirect_uri=${reactAppRedirectSignin}&scope=email+openid`
  );
  window.location.href = `https://${process.env.REACT_APP_OKTA_COGNITO_SUBDOMAIN}/oauth2/authorize?response_type=TOKEN&identity_provider=Okta&client_id=${process.env.REACT_APP_OKTA_COGNITO_CLIENT}&redirect_uri=${reactAppRedirectSignin}&scope=email+openid`;
}

const handleOnboardBroker = () => {
  window.location.href = '/brokeronboard/index.html?type=broker';
}

const redirectToSupplierOnboard = () => {
  window.location.href = '/brokeronboard/index.html?type=supplier';
}

let displayNoneMobile = isMobile ? {display: 'none'} : {};

const mobileInlineStyle = (mobileStyle, webStyle) => {
  webStyle = webStyle || {};
  return isMobile ? mobileStyle : webStyle;
};

function LoginPage() {
  return (
    <div className="wrapper login-wrapper">
      <div className={ `privacy-policy${isMobile ? ' mobile': ''}`}>
        <div className='policy-row'>
          <span>© {moment().format('YYYY')} Sysco Corporation | </span>
          <span>
            <a class="text-white"
              href="https://sysco.com/Privacy-Policy.html"
              target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </span>
        </div>
        <div className='policy-row'>
          <span>
            <a class="text-white"
              href="https://privacyportal.onetrust.com/webform/6a7eb96f-702a-4b18-a6f3-67bdff91cc97/85a666bf-1859-4df0-9bbe-0bd8409f0db8"
              target="_blank" rel="noopener noreferrer">Do Not Sell or Share My Personal Information</a> |
          </span>
          <span>
            <a class="text-white"
              href="https://privacyportal.onetrust.com/webform/6a7eb96f-702a-4b18-a6f3-67bdff91cc97/338eba68-062c-4da3-9e30-cd87d5b8247b"
              target="_blank" rel="noopener noreferrer"> Limit the Use of My Sensitive Personal Information</a>
          </span>
        </div>
      </div>
      <div className="login-panel user-selection" style={mobileInlineStyle({width: '100%'})}>
        <div className="option-panel-wrapper" style={mobileInlineStyle({width: '73%'})}>
          <div className="suite-logo">
            <img src={logo} alt="Sysco Supplier Suite" className="logo" />{!isMobile && <>|<span>Supplier Suite</span></>}
          </div>
          <div className="options-inner-wrapper">
            <div className="option-login" style={mobileInlineStyle({borderRight: '0px'})}>
              <span className="login-text">Welcome to Sysco{isMobile && <> Supplier Suite</>}!<br />To continue, Please login below.</span>
              <div className="button" onClick={handleClickVendorOrBroker}>LOGIN</div>
              <div className="login-text associate-text">
                Sysco Colleague?
                <span className="login-internal" onClick={handleClick}>Login here</span>
              </div>
              <div className="seperator-or" style={displayNoneMobile}><span className="login-text">Or</span></div>
            </div>
            <div className="option-new-user" style={displayNoneMobile}>
              <div className="login-text">New user?&nbsp;&nbsp;Join today!</div>
              <div className="button white new-buttons" onClick={() => handleOnboardBroker()}>Broker</div>
              <div className="button white new-buttons" onClick={()=>redirectToSupplierOnboard()}>Supplier</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
